.navbar-container {
    margin-top: 20px;
    width: 100%;
    box-shadow: 0 -1px 2px rgba(125, 125, 125, 0.1), /* Shadow above the navbar */
                0 1px 2px rgba(125, 125, 125, 0.1);  /* Shadow below the navbar */
                
}

.navbar {
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
}

.nav-item {
    width: fit-content;
    padding: 10px 25px;
    display: inline-block;
    text-align: center;
}

.nav-item > a {
    text-decoration: none;
    color: rgb(128, 128, 128);
    position: relative;
    z-index: 1;
    font-family: 'Cormorant Garamond';
    font-size: 16px;
}

.nav-item > a:hover {
    text-decoration: none;
    color: black;
}