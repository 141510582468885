.booking-process, .packages {
    width: 100%;
}

.offerings-container {
    width: 100%;
    display: flex;
    flex-direction: row;
}

.img-col {
    width: 35%;
}

.package-col {
    width: 65%;
    font-family: 'Cinzel';
}

.package-button-container {
    display: flex;
    gap: 10px;
    position: absolute;
    justify-content: center;
    margin-top: 20px;
    left: 50%;
    transform: translate(-50%, -50%);
}

.img-col > img {
    display: block;
    margin-right: auto;
    overflow-y: visible;
    text-align: center;
    width: 100%;
}

.package-col {
    margin-right: auto;
    padding-top: 20px;
}

.experience-details {
    margin-top: 50px;
    margin-left: 40px;
    margin-bottom: 40px;
}

.experience-title {
    font-size: 35px;
    letter-spacing: 3px;
    padding-top: 40px;
    padding-bottom: 10px;
}

.package-details {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.package-pricing {
    width: 100%;
    margin-top: 30px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.detail-col {
    width: 50%;
    margin-right: auto;
    font-size: 22px;
    letter-spacing: 3px;
}

.detail-col > p {
    font-family: 'Cormorant Garamond';
    font-size: 16px;
    letter-spacing: 0;
    margin-top: 0px;
    margin-bottom: 0px;
    line-height: 1.7;
}

.sm-screen-price {
    font-size: 15px;
    padding-top: 20px;
    padding-bottom: 20px;
}

.price {
    margin-right: auto;
    bottom: 0;
}

.offering-button, .portfolio-button {
    border: 1px solid rgb(144, 144, 144);
    background-color: white;
    padding: 10px 15px;
    font-family: 'Cormorant Garamond';
    font-size: 17px;
    color: grey;
    cursor: pointer;
    text-decoration: none;
}

.offering-button-active {
    border: none;
    background-color: grey;
    padding: 10px 15px;
    font-family: 'Cormorant Garamond';
    font-size: 17px;
    color: white;
    cursor: pointer;
    text-decoration: none;
}

.portfolio-button-container {
    margin-left: 40px;
}

@media only screen and (min-width: 400px) {
    .sm-screen-price {
        display: none;
    }
}

@media only screen and (max-width: 1200px) {
    .offerings-container {
        min-height: 600px;
    }
}

@media only screen and (max-width: 1100px) {
    .package-button-container {
        padding-left: 30px;
    }
}

@media only screen and (max-width: 1000px) {
    .package-button-container {
        padding-left: 70px;
    }
}

@media only screen and (max-width: 900px) {
    .offerings-container {
        flex-direction: column;  
        padding-bottom: 30px;
    }

    .offerings-container > .img-col {
        width: 100%;
    }

    .package-button-container {
        padding-left: 0;
    }

    .package-col {
        width: 100%;
    }

    .experience-title {
        font-size: 25px;
    }

    .detail-col {
        font-size: 19px;
        letter-spacing: 2px;
    }
    
    .img-col > img {
        margin-left: auto;
        width: 50%;
    }
}

@media only screen and (max-width: 550px) {
    .package-details {
        flex-direction: column;
    }

    .detail-col {
        width: 100%;
        margin-top: 20px;
    }

    .package-pricing {
        display: none;
    }

    .experience-title {
        padding-bottom: 0;
        margin-bottom: 0;
    }
}

.questions-title {
    font-family: 'Cormorant Garamond';
    font-size: 50px;
    font-weight: 200;
    text-align: center;
    width: 100%;
}

.questions-container {
    margin-top: 50px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 20px;
}

.first-row {
    flex: 1;
    display: flex;
    gap: 30px;
}

.second-row {
    flex: 1;
    display: flex;
    gap: 30px;
}

.qa-content {
    flex: 1;
}

.left-qa {
    text-align: right;
    margin-left: auto;
    padding-left: 30px;
}

.right-qa {
    text-align: left;
    padding-right: 30px;
}

.question {
    font-family: 'Cormorant Garamond';
    font-size: 19px;
}

.answer {
    margin-top: 10px;
    font-family: 'Cormorant Garamond';
    font-size: 16px;
    line-height: 1.6;
}

@media only screen and (max-width: 600px) {
    .questions-title {
        font-size: 35px;
    }

    .questions-container {
        margin-top: 30px;
    }
}