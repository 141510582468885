.portfolio-type-container {
    display: flex;
    gap: 10px;
    position: absolute;
    justify-content: center;
    padding-top: 100px;
    padding-bottom: 20px;
    left: 50%;
    transform: translate(-50%, -50%);
}

.offering-button {
    border: 1px solid rgb(144, 144, 144);
    background-color: white;
    padding: 10px 15px;
    font-family: 'Cormorant Garamond';
    font-size: 17px;
    color: grey;
    cursor: pointer;
    text-decoration: none;
}

.offering-button-active {
    border: none;
    background-color: grey;
    padding: 10px 15px;
    font-family: 'Cormorant Garamond';
    font-size: 17px;
    color: white;
    cursor: pointer;
    text-decoration: none;
}

.gallery-container {
    width: calc(100vw - 80px);
    padding-top: 80px;
    padding-left: 30px;
    padding-right: 30px;
    background-color: #FFFCFA;
}

.yarl__navigation_prev, .yarl__navigation_next {
    display: flex;
    width: 300px;
}

.yarl__navigation_prev {
    justify-content: flex-start;
}

.yarl__navigation_next {
    justify-content: flex-end;
}