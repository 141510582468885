.inquire-title {
    display: flex;
    justify-content: center;
    padding-right: 150px;
}

.inquire-img {
    width: 200px;
}

.contact-form {
    width: 100%;
    display: flex;
    grid-auto-flow: column;
    padding-top: 20px;
    padding-bottom: 50px;
}

.form-column {
    flex-basis: 50%;
    padding-bottom: 30px;
}

.form-column > img {
    overflow-y: visible;
    padding-left: 20%;
    width: 70%;
}

.form-col {
    margin-right: auto;
    padding-top: 20px;
}

.form-submit {
    margin-top: 10px;
    padding: 8px 10px;
    margin-left: auto;
    float: right;
    border: none;
    border-radius: 5px;
    font-family: 'Cormorant Garamond';
    font-size: 16px;
    background-color: #e5c071;
    color: white;
    cursor: pointer;
    text-decoration: none;
}

.input-block {
    padding: 5px 150px 15px 50px;
    font-size: 16px;
    font-family: 'Cormorant Garamond';
}

.input-block > input, select, textarea {
    width: 100%;
    margin-top: 5px;
    display: block;
    outline: none;
    border: 1px solid rgb(232, 229, 229);
    border-radius: 2px;
    -ms-box-sizing:content-box;
    -moz-box-sizing:content-box;
    box-sizing:content-box;
    -webkit-box-sizing:content-box; 
    padding: 0 5px;
    font-size: 16px;
    font-family: 'Cormorant Garamond';
}

.input-block > input, select {
    padding: 0.5% 1%;
}

.input-block > select {
    padding-left: 2px;
}

.input-block > textarea {
    height: 150px;
    resize: none;
}

@media only screen and (max-width: 900px) {
    .contact-form > .img-col {
        display: none;
    }

    .form-col {
        flex-basis: 100%;
    }

    .input-block {
        padding: 5px 40px;
    }

    .input-block, .input-block > input, select, textarea {
        font-size: 15px;
    }
}

@media only screen and (max-width: 600px) {
    .inquire-title {
        display: flex;
        padding-right: 0px;
    }
    
    .inquire-img {
        width: 150px;
    }
}