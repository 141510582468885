.home-title {
    font-family: 'Cinzel';
    letter-spacing: 3px;
    font-size: 12px;
    width: 100%;
    text-align: center;
    position: absolute;
    top: 150px;
}

.story-intro {
    margin-left: auto;
    margin-right: auto;
    width: fit-content;
    display: flex;
    gap: 30px;
}

.story-column {
    width: 50%;
    flex-direction: column;
    padding: 5px;
    font-family: 'Cormorant Garamond';
    line-height: 22px;
}

.story-left {
    padding-left: 10%;
}

.story-right {
    padding-right: 10%;
}

.title-container {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    margin-top: -40px;
}

.motto-container {
    width: 70%;
    margin-left: auto;
    margin-right: auto;
}

.page-title {
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 10px;
    text-align: center;
    font-family: 'Cormorant Garamond';
    font-size: 20px;
}

.motto {
    width: 100%;
}

@media only screen and (max-width: 800px) {
    .story-intro {
        display: block;
    }

    .story-column {
        width: 80%;
    }

    .story-left, .story-right {
        padding-left: 10%;
        padding-right: 10%;
    }

    .story-right {
        margin-top: -1em;
    }

    .motto-container {
        width: 90%;
    }
}

@media only screen and (max-width: 600px) {
    .home-title {
        letter-spacing: 1px;
        font-size: 11px;
    }

    .motto-container {
        width: 100%;
        transform: scale(1.05);
    }
}