.content-first-block {
    display: inline-block;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    display: flex;
    gap: 30px;
    height: 800px;
    padding-top: 20px;
}

.content-slogan {
    display: block;
    height: 100px;
    text-align: center;
    font-family: 'Cormorant Garamond';
    font-style: italic;
    font-size: 20px;
}

.slide-column {
    width: 500px;
    flex-direction: column;
}

.slide {
    position: absolute;
}

.slide > img {
    width: 500px;
}

.content-first-block .first-slide {
    animation: first-slide 20s ease infinite;
}
.content-first-block .second-slide {
    animation: second-slide 20s ease infinite;
}
.content-first-block .third-slide {
    animation: third-slide 20s ease infinite;
}
.content-first-block .fourth-slide {
    animation: fourth-slide 20s ease infinite;
}

@keyframes first-slide {
    0%    {opacity: 1;}
    20%   {opacity: 1;}
    25%   {opacity: 0;}
    50%   {opacity: 0;}
    75%   {opacity: 0;}
    100% { opacity: 1;}
}
@keyframes second-slide {
    0%    {opacity: 0;}
    20%   {opacity: 0;}
    25%   {opacity: 1;}
    45%   {opacity: 1;}
    50%   {opacity: 0;}
    100% { opacity: 0;}
}
@keyframes third-slide {
    0%    {opacity: 0;}
    45%   {opacity: 0;}
    50%   {opacity: 1;}
    70%   {opacity: 1;}
    75%   {opacity: 0;}
    100% { opacity: 0;}
}
@keyframes fourth-slide {
    0%    {opacity: 0;}
    70%   {opacity: 0;}
    75%   {opacity: 1;}
    95%   {opacity: 1;}
    100% { opacity: 0;}
}

@media only screen and (max-width: 1100px) {
    .content-first-block {
        height: 630px;
    }
    .slide-column, .slide > img {
        width: 400px;
    }
}

@media only screen and (max-width: 880px) {
    .content-first-block {
        height: 500px;
    }
    .slide-column, .slide > img {
        width: 320px;
    }
}

@media only screen and (max-width: 730px) {
    .content-first-block {
        height: 470px;
        gap: 20px;
    }
    .slide-column, .slide > img {
        width: 290px;
    }
}

/* smallest iPhone screen about 640px */
@media only screen and (max-width: 620px) {
    .content-first-block {
        height: 390px;
    }
    .slide-column, .slide > img {
        width: 240px;
    }
}

@media only screen and (max-width: 550px) {
    .content-first-block {
        height: 370px;
    }
    .slide-column, .slide > img {
        width: 220px;
    }
}

@media only screen and (max-width: 500px) {
    .content-first-block {
        height: 340px;
    }
    .slide-column, .slide > img {
        width: 210px;
    }
}

@media only screen and (max-width: 450px) {
    .content-first-block {
        height: 270px;
    }
    .slide-column, .slide > img {
        width: 150px;
    }
}

