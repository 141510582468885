.app-footer {
    align-content: center;
    background-color: #FFFCFA;
}

.icon-container {
    margin-left: 10px;
    margin-right: 10px;
    vertical-align: top;
}

.socials {
    margin-top: 20px;
    padding-right: 10px;
    height: 40px;
    float: right;
}

.contact-button {
    border: 1px solid rgb(144, 144, 144);
    background-color: white;
    padding: 15px 20px;
    font-family: 'Cormorant Garamond';
    font-size: 20px;
    color: grey;
    cursor: pointer;
    text-decoration: none;
}

.promo {
    font-family: 'Cormorant Garamond';
    font-style: italic;
}

.flexbox {
    display: flex;
    flex-direction: column;
    align-items: center; /* Vertically center */
    justify-content: center; /* Horizontally center */
    width: 100%;
    white-space: pre-wrap;
}

@media only screen and (max-width: 1100px) {
    .contact-button {
        font-size: 18px;
    }
    .socials {
        height: 30px;
    }
}

@media only screen and (max-width: 880px) {
    .contact-button {
        font-size: 16px;
    }
    .socials {
        height: 20px;
    }
}
